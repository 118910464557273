.footer {
	padding: 2rem 0rem;
	background: black;
}

.footer > h1 {
	color: white;
	margin: auto;
	margin-top: 0;
	margin-bottom: 3rem;
}

.social-links {
	margin: 0 auto;
	width: 100%;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}

.fab {
	color: white;
	transform: scale(2);
}

.social-links > a {
	text-decoration: none;
}

@media screen and (min-width: 640px) {
	.footer {
		padding: 3rem;
	}
	.social-links {
		width: 50%;
	}
}
