#skills {
height: auto;
width: 100%;

text-align: left;
padding-bottom: 2rem;
}

#skills > h1 {
color: white;
margin-top: -1rem;
padding: 2rem 0rem 0rem 2rem;
margin: auto;
font-size: 2rem;
}

.skills-container {
height: 300px;
width: 300px;
border-radius: 50%;
position: relative;
margin: auto;
animation: rotateSkills 25s linear infinite;
}

.skills-container .logo-image {
--rotation: 0;
position: absolute;
text-align: center;
width: 100%;
height: 100%;
transform: rotate(var(--rotation));
}

.heart {
width: 2rem;
height: 2rem;
background-color: red;
top: 11rem;
left: 0rem;
position: relative;
margin: auto;
transform: rotate(135deg);
animation: heartBeat 1.4s linear infinite;
}


.heart::before {
content: "";
height: 100%;
width: 100%;
position: absolute;
top: 0;
left: 0;
transform: translateY(50%);
z-index: 330;
background-color: red;
border-radius: 50%;
}

.heart::after {
	content: "";
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	transform: translateX(-50%);
	z-index: 331;
	background-color: red;
	border-radius: 50%;
}

.skills-container .image-1 {
--rotation: 40deg;
}

.skills-container .image-2 {
--rotation: 80deg;
}
.skills-container .image-3 {
--rotation: 120deg;
}
.skills-container .image-4 {
--rotation: 160deg;
}
.skills-container .image-5 {
--rotation: 200deg;
}
.skills-container .image-6 {
--rotation: 240deg;
}
.skills-container .image-7 {
--rotation: 280deg;
}
.skills-container .image-8 {
--rotation: 320deg;
}
.skills-container .image-9 {
--rotation: 360deg;
}

@keyframes heartBeat {
0% {
	transform: rotate(135deg) scale(1);
}
20% {
	transform: rotate(135deg) scale(1);
}
30% {
	transform: rotate(135deg) scale(1.4);
}
50% {
	transform: rotate(135deg) scale(1.2);
}
70% {
	transform: rotate(135deg) scale(1.4);
}
100% {
	transform: rotate(135deg) scale(1);
}
}

@keyframes rotateSkills {
0% {
	transform: rotateZ(0);
	/* height: 700px;
	width: 700px; */
}
100% {
	transform: rotateZ(360deg);
}
}

.skill-image {
height: 3rem;
width: 3rem;
}

@media screen and (min-width: 640px) {
.skills-container {
	height: 650px;
	width: 650px;
}

#skills > h1 {
	margin-top: -1rem;
	margin-left: 13.75rem;
	padding: 3rem 0rem 0rem 3rem;
	margin-bottom: -1rem;
	font-size: 3rem;
}

.heart {
	height: 4rem;
	width: 4rem;
	top: 23.5rem;
	left: -0.1rem;
}

.skill-image {
	height: auto;
	width: auto;
}
}
