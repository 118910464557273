.projects-container {
	height: auto;
	width: 90%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: auto;
	padding: 1rem 0;
	
	/* background: rgb(49, 49, 49); */
}

.work-heading {
	color: white;
	font-size: 2rem;
	padding-top: 2rem;
	margin: 0;
}

.all-projects-btn {
	text-decoration: none;
	color: white;
	background-color: black;
	border: 2px solid white;
	padding: 1rem;
	font-size: 1.25rem;
	margin: 1rem auto;
}

@media screen and (min-width: 640px) {
	.projects-container {
		width: 95%;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		row-gap: 1rem;
		column-gap: 1rem;
		padding: 3rem 1rem;
	}
	.work-heading {
		font-size: 3rem;
		padding-top: 3rem;
		margin: auto;
	}
}
