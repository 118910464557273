.animation-section {
	width: 300px;
	height: 300px;
	background: rgb(0, 0, 0);
	margin: auto;
}

.main-text {
	padding-top: 1em;
	margin: auto;
	font-size: 2rem;
}