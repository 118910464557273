.SetAppointments-container {
	position: relative;
	margin: 1rem auto;
	width: 100%;
}

.button-link {
	display: block;
	position: relative;
	width: 20rem;
	z-index: 50;
	padding: 0.25rem;
	font-size: 1.1rem;
	background: transparent;
	margin: 0.25rem;
	color: white;
	text-decoration: none;
	border-radius: 32px;
	text-align: center;
	overflow: hidden;
	border: 2px solid white;
}

.button-link::before {
	content: "";
	width: 10%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	background-color: rgb(91, 217, 248);
	z-index: -100;
	transform: skew(-25deg);
	transition: all 0.7s ease-in-out;
	animation: hoverBtnAnimation 4s linear infinite;
}

.button-link:hover::before {
	animation: none;
	transform: skew(0);
	width: 110%;
}

@keyframes hoverBtnAnimation {
	from {
		left: -100px;
	}
	to {
		left: 100%;
	}
}

.SetAppointments-description {
	color: white;
	font-size: 1rem;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background-color: rgba(0, 0, 0, 0.774);
}

@media screen and (min-width: 640px) {
	.SetAppointments-container {
		height: 350px;
		margin: auto;
		border: 0px solid white;
	}

	.button-link {
		height: auto;
		padding: 0.75rem;
		margin: 1rem;
	}

	.SetAppointments-description {
		font-size: 2rem;
	}
}
